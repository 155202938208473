/* global I18n */
import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const disabledToolTip = () => (
  <Tooltip id="create-collection-tooltip">
    {I18n.t('turbosquid_products.invalid_bundles')}
  </Tooltip>
)

const enabledToolTip = () => (
  <Tooltip id="create-collection-tooltip">
    {I18n.t('turbosquid_products.valid_bundles')}
  </Tooltip>
)

const CreateBundle = (props) => {
  const { disabled, createCollection, products } = props
  const disabledClassName = disabled ? 'disabled' : ''
  return (
    <>
    <OverlayTrigger placement="right" overlay={disabled ? disabledToolTip() : enabledToolTip()}>
      <div className={disabledClassName}>
        <button
          type="button"
          id="create-new-bundle"
          className="btn create-bundle-btn"
          disabled={disabled}
          onClick={createCollection}
          style={{marginRight: '4px' }}
        >
          {I18n.t('turbosquid_products.create_bundle')}
        </button>
      </div>
    </OverlayTrigger>
    </>
  )
}

export default CreateBundle

CreateBundle.propTypes = {
  createCollection: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

CreateBundle.defaultProps = {
  disabled: false,
}
