import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import useAutosaveForm from "./useAutoSaveForm";
import { ProductContext } from "../contexts/ProductContext";

export default function useMetadataForm(metadata, initialValues = {}) {
  const productContext = useContext(ProductContext)

  const [bundleTitle, setBundleTitle] = useState('')
	const [bundlePrice, setBundlePrice] = useState('')
	const [bundleDescription, setBundleDescription] = useState('')
	const [isPublishing, setIsPublishing] = useState(false)
  const [bundlePriceValidation, setBundlePriceValidation] = useState(null)
	
	// TODO: I do not have autosave working but worth leaving here if we want to explore later

  // const {
  //   isReady,
  //   patch,
  //   values,
  //   isPatching,
  //   form
  // } = useAutosaveForm({
  //   item: metadata,
  //   initialValues,
  //   resourceContext: productContext,
  //   onUpdate: (updated) => {}
  // })

  return {
    // ...form,
    // patch,
    // isPatching,
    // values,
    // isReady,
		isPublishing,
		setIsPublishing,
		bundleTitle,
		setBundleTitle,
		bundlePrice,
		setBundlePrice,
		bundleDescription,
		setBundleDescription,
    bundlePriceValidation,
    setBundlePriceValidation
  }
}