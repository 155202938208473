import $ from 'jquery';
$(function() {
  if ($('#js-tax-interview').length) {
    window.onpopstate = function(event){
      const index = event != null ? (event.state ? event.state.stepIndex : 0) : 0;

      const $progressBarSteps = $('#js-tax-interview-progress-bar li');
      $progressBarSteps.removeClass('current');
      const $previousProgressBarSteps = $progressBarSteps.slice(0, index + 1);
      $previousProgressBarSteps.addClass('current');

      const $taxInterviewSteps = $('#js-tax-interview-steps li.js-tax-interview-step');
      $taxInterviewSteps.hide();

      const $previousStep = $taxInterviewSteps.eq(index);
      $previousStep.show();
    };

    $('#js-tax-interview-steps').on('click', '.js-tax-interview-pager-previous', function(event){
      history.back();
      return event.preventDefault();
    });

    $(window).on('beforeunload', () => 'All progress will be lost!');

    $(document).on('change', '.js-tax-interview-step .has-error input, .js-tax-interview-step .has-error select',function(){
      const currentStep = getCurrentStep();
      const form = $(`#${currentStep} form`);
      const formData = form.serializeArray();
      const fieldName = $(this).attr('name');
      const containerDom = $(this).parents('.has-error');
      formData.push({ name: `tax_interview_step_${getCurrentStep(true)}_form[is_validation]`, value: 1 })
      $.ajax({
        type: 'POST',
        dataType : 'json',
        url: form.attr('action'),
        data: $.param(formData),
        success: function (result) {
          if (fieldName && containerDom.length > 0 && (!result || !result.errors[fieldName.replace(/.*\[|\]/g, '')])) {
            $(containerDom[0]).removeClass('has-error');
          } else if (containerDom.length > 0 && result && result.errors[fieldName.replace(/.*\[|\]/g, '')]) {
            $(containerDom[0]).find('.help-block').html(result.errors[fieldName.replace(/.*\[|\]/g, '')][0]);
          }
        }
      });
    });
  }
});

function getCurrentStep(onlyStep = false) {
  let currentStep = 'js-tax-interview-step-one';
  $('.js-tax-interview-step').each(function () {
    if ($(this).css('display') !== 'none' && $(this).html().length > 0) {
      currentStep = $(this).attr('id');
      if (onlyStep && currentStep) {
        currentStep = currentStep.replace('js-tax-interview-step-', '')
      }
    }
  })
  return currentStep;
}

class Step {
  constructor() {
    this.toggle = this.toggle.bind(this);
    this.setRequired = this.setRequired.bind(this);
    this.init();
  }

  init(){
    //empty function;
  }
  toggle($formGroup, show){
    $formGroup.toggleClass('hidden', !show).toggle(show);
    this.setRequired($formGroup, show);
  }

  setRequired($formGroup, required){
    const $optional = $formGroup.find('label span.text-muted');
    const $input = $formGroup.find('input, select');

    $optional.toggle(!required);
    $input.attr('required', required ? 'required' : null);
  }
}

//window.Step = Step

class StepTwo extends Step {
  static onload() {
    $('.select2-select').select2({
      allowClear: true,
      placeholder: 'Select an option'
    });

    return new StepTwo();
  }
  init() {
    this.entityTypeChangeHandler = this.entityTypeChangeHandler.bind(this);
    this.showDateOfBirth = this.showDateOfBirth.bind(this);
    this.showDateOfBirthgowCompanyName = this.showDateOfBirthgowCompanyName.bind(this);
    this.showLimitedLiabilityCompany = this.showLimitedLiabilityCompany.bind(this);
    this.isUSEntity = this.isUSEntity.bind(this);
    this.isIndividual = this.isIndividual.bind(this);
    this.showCompanyName = this.showCompanyName.bind(this);
    this.showStates = this.showStates.bind(this);
    this.$stepTwoContainter = $('#js-tax-interview-step-two');
    this.$entityType = this.$stepTwoContainter.find('select[name="tax_interview_step_two_form[entity_type]"]');
    this.$isUSEntity = this.$stepTwoContainter.find('input[name="tax_interview_step_two_form[is_us_entity]"]');
    this.$dateOfBirthFormGroup = this.$stepTwoContainter.find('div.form-group.tax_interview_step_two_form_date_of_birth');
    this.$companyNameFormGroup = this.$stepTwoContainter.find('div.form-group.tax_interview_step_two_form_company_name');
    this.$limitedLiabilityCompanyFormGroup = this.$stepTwoContainter.find('div.form-group.tax_interview_step_two_form_limited_liability_company');
    this.$entityType.change(this.entityTypeChangeHandler);
    this.$showStateOnLoad = this.showStates();
    this.$onChangeCountry = $('.select-country').on('select2:select', (e) => {
      return this.showStates(e.params.data.id)
    });
  }
  showStates(country) {
    const form = $("#new_tax_interview_step_two_form");
    const inputState = $("#optional-state").parent();
    const selectStateUs = $("#required-state-us").parent();
    const selectStateCa = $("#required-state-ca").parent();
    const sibling = $(".tax_interview_step_two_form_city");
    const elements = [inputState, selectStateUs, selectStateCa];

    //remove items from form
    elements.map(el => form.after(el.hide()));

    const selectedCountry = country ? country : $('.select-country').select2('data')[0].id;
    if(selectedCountry === "US") {
      //add select to form
      sibling.after(selectStateUs.show());
      return false;
    } else if (selectedCountry === "CA") {
      sibling.after(selectStateCa.show());
      return false;
    }

    //add input to form
    sibling.after(inputState.show());

  }
  entityTypeChangeHandler(event){
    const newEntityType = $(event.target).val();
    if(newEntityType==='individual'){
      $("#tax_interview_step_two_form_limited_liability_company_false").prop("checked", true);
    }

    this.toggle(this.$dateOfBirthFormGroup, this.showDateOfBirth(newEntityType));
    this.toggle(this.$companyNameFormGroup, this.showCompanyName(newEntityType));
    this.toggle(this.$limitedLiabilityCompanyFormGroup, this.showLimitedLiabilityCompany(newEntityType));
  }

  showDateOfBirth(entityType){
    return (entityType.length === 0) || (!this.isUSEntity() && this.isIndividual(entityType));
  }

  showDateOfBirthgowCompanyName(entityType){
    return (entityType.length === 0) || !this.isIndividual(entityType);
  }

  showLimitedLiabilityCompany(entityType){
    return (entityType.length === 0) || !this.isIndividual(entityType);
  }

  isUSEntity() {
    return this.$isUSEntity.val() === 'true';
  }

  isIndividual(entityType){
    if (entityType == null) { 
      entityType = this.$entityType.val(); 
      $("#tax_interview_step_two_form_limited_liability_company_false").prop("checked", true);
    }
    return entityType === 'individual';
  }

  showCompanyName(entityType){
    return (entityType.length === 0) || !this.isIndividual(entityType);
  }
}

class StepFive extends Step {
  static onload() {
    return new StepFive;
  }

  static onerror() {
    $('#js-tax-submit-error-modal').modal('show');
  }
  consentToElectronicSignatureChangeHandler(event){
    const consentToElectronicSignature = $(event.target).val() === 'true';
    this.$signElectonically.toggle(consentToElectronicSignature);
    this.toggle(this.$signElectonicallyFormGroups, consentToElectronicSignature);
  }
  init(){
    this.consentToElectronicSignatureChangeHandler = this.consentToElectronicSignatureChangeHandler.bind(this);
    this.$signElectonically = $('#js-sign-electronically');
    this.$stepFiveContainter = $('#js-tax-interview-step-five');
    this.$consentToElectronicSignature = this.$stepFiveContainter.find('input[name="tax_interview_step_five_form[electronic_signature_consent]"]');
    this.$signElectonicallyFormGroups = this.$signElectonically.find('div.form-group');
    this.$consentToElectronicSignature.change(this.consentToElectronicSignatureChangeHandler);
  }
}

window.StepOne = null;
window.StepTwo = StepTwo;
window.StepThree = null;
window.StepFour = null;
window.StepFive = StepFive;
