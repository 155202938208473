import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'
import infoIcon from '../../../assets/images/bundles/info.svg'
function AlertBox(props) {
  const { resetAlertBox, timeout, type, message, isBundleAlert, info } = props
  setTimeout(() => { resetAlertBox() }, timeout || 1800000)

  return (
    <Alert bsStyle={type} onDismiss={resetAlertBox} style={{padding: isBundleAlert ? '1rem 3rem 0.5rem 1.25rem': ''}}
    >
      {info &&
        <img src={infoIcon} style={{marginBottom: '3px', marginRight: '2px', maxHeight: '15px', color: 'blue'}} />
      }
      {message}
    </Alert>
  )
}

export default AlertBox

AlertBox.propTypes = {
  resetAlertBox: PropTypes.func.isRequired,
  timeout: PropTypes.number,
  type: PropTypes.string,
  message: PropTypes.string.isRequired,
}

AlertBox.defaultProps = {
  timeout: 1800000,
  type: '',
}
