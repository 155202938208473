/* global gon */
import React from 'react'
import PropTypes from 'prop-types'
import { draftEditPageUrl } from './helpers'
import { bundleDraftEditPageUrl } from './helpers'

const DraftNameCell = ({
  id,
  name,
  is_bundle,
}) => (
  <div style={{ maxWidth: '100%' }}>
    <a href={is_bundle === 1 ? bundleDraftEditPageUrl(id) : draftEditPageUrl(id)} rel="noopener noreferrer" title={name}>
      <span className="my-drafts__item-thumbnail-wrapper my-drafts__item-thumbnail-wrapper-thumbnail" />
      <div className="details my-products__item-details" style={{ maxWidth: '80%' }}>
        <h5 className="name my-products__item-details">{ name }</h5>
      </div>
    </a>
  </div>
)

DraftNameCell.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default DraftNameCell
