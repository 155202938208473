/* global gon */
import React, { useContext, useState } from 'react'
// import ReactSpinner from '../../../products/react-spinner'
import ReactSpinner from '../react-spinner'
import DiscardModal from '../../components/publisher/EditHeader/DiscardModal'
import BundlesPublishModal from '../../components/publisher/EditHeader/BundlesPublishModal'
import { ProductContext } from '../../contexts/ProductContext'
import { BundlesContext } from './BundlesProvider'
import { publishBundleProduct } from '../../services/product-service'
import { deleteDraft } from '../../services/draft-service'
import usePreviews from '../../hooks/usePreviews'
const i18nOptions = {
  scope: 'turbosquid.products.product_header',
}
import { MAX_BUNDLE_PRODUCTS_COUNT, MIN_BUNDLE_PRODUCTS_COUNT } from '../../components/products/constants'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function PublisherControls({}) {
  const { draftId, publishDraft, isValid, isDeleting, isSaving, links, product } = useContext(ProductContext)
  const [showDiscardModal, setShowDiscardModal] = useState(false)
  const [showPublishModal, setShowPublishModal] = useState(false)
  const isPublishDisabled = isDeleting || isSaving
  const isUpdating = isDeleting || isSaving
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content

	const {
		metadataForm,
		searchSelect
	} = useContext(BundlesContext)

	const {
		bundleTitle,
		bundlePrice,
		bundleDescription,
		isPublishing,
		setIsPublishing,
    bundlePriceValidation,
    setBundlePriceValidation
	} = metadataForm

	const {
    orderedPreviews
  } = usePreviews()

  const handlePublishDisabled = () => {
    if (!bundlePrice || (isNaN(bundlePrice))) {
      setBundlePriceValidation({type: 'minLength'})
      return true
    }
    if (orderedPreviews.length < 5) {
      return true 
    }
    if (bundleDescription.length < 0) {
      return true
    }
    if (bundlePriceValidation) {
      return true
    }
    return false
  }

  const productLimits = () => {
    if (Object.keys(selectedSearchResults).length > MAX_BUNDLE_PRODUCTS_COUNT) {
      return true
    }
    if (Object.keys(selectedSearchResults).length < MIN_BUNDLE_PRODUCTS_COUNT) {
      return true
    }
    return false
  }

  const {
    selectedSearchResults,
  } = searchSelect

  const handleDiscardDraft = () => {
		setIsPublishing(true)
    deleteDraft(draftId, csrfToken)
    setShowDiscardModal(false)
  }

	const handlePublishDraft = () => {
    if (handlePublishDisabled()) {
      setShowPublishModal(true)
      return
    }
		setIsPublishing(true)
		const bundleAssetList = Object.keys(selectedSearchResults).map(key => `${key}`).join(',')
		const options = {
			id: draftId,
			draft_id: draftId,
			price: Math.floor(bundlePrice).toFixed(),
			name: bundleTitle,
			description: bundleDescription,
			thumbnails: orderedPreviews,
			bundle_asset_list: bundleAssetList
		}
		publishBundleProduct(options, csrfToken, true)
	}

	const handleSaveDraft = () => {
		setIsPublishing(true)
		const bundleAssetList = Object.keys(selectedSearchResults).map(key => `${key}`).join(',')
    const options = {
			id: draftId,
			draft_id: draftId,
			price: isNaN(bundlePrice) ? '' : Math.floor(bundlePrice).toFixed(),
			name: bundleTitle,
			description: bundleDescription,
			thumbnails: orderedPreviews,
			bundle_asset_list: bundleAssetList,
      status: 'draft'
		}
		 publishBundleProduct(options, csrfToken, false).then(() => {
       setIsPublishing(false)
     })
	}

  const handlePreviewDraft = () => {
    setIsPublishing(true)
		const bundleAssetList = Object.keys(selectedSearchResults).map(key => `${key}`).join(',')
		const options = {
			id: draftId,
			draft_id: draftId,
			price: isNaN(bundlePrice) ? '' : Math.floor(bundlePrice).toFixed(),
			name: bundleTitle,
			description: bundleDescription,
			thumbnails: orderedPreviews,
			bundle_asset_list: bundleAssetList,
      status: 'draft'
		}
		 publishBundleProduct(options, csrfToken, false).then(() => {
       setIsPublishing(false)
       window.location.href = `${gon.external_turbosquid_draft_preview_url}${draftId}`
     })
  }

  const previewDisabled = () => {
    return Object.keys(selectedSearchResults).length < 2
  }

  const tooltip = () => (
    previewDisabled() ? (
      <Tooltip id="preview-bundle-tooltip">
      {I18n.t('turbosquid_products.preview_draft')}
    </Tooltip>
    ) : (
      <></>
    )
  )

  return (
      <div className="pull-right action-btns top-btns">
        <p className="autosave-message">
          <button
            type="button"
            data-testid="discard-bundle"
            className="btn btn-default btn-discard"
            disabled={!draftId}
            onClick={() => setShowDiscardModal(true)}>
            {I18n.t('discard_draft', i18nOptions)}
          </button>
          <DiscardModal
            show={showDiscardModal}
            hideModal={() => setShowDiscardModal(false)}
            discardDraft={handleDiscardDraft}
          />
					 <button
            type="button"
            data-testid="save-bundle"
            name="commit"
            className={`btn btn-default btn-save`}
            disabled={false}
            // ref={(input) => { this.saveDraftRef = input }}
            onClick={handleSaveDraft}
          >
            {I18n.t('save_draft', i18nOptions)}
          </button>
        </p>
        <div className="action-buttons-wrapper">
          <OverlayTrigger placement="left" overlay={tooltip()}>
            <button
              onClick={handlePreviewDraft}
              disabled={previewDisabled()}
              className='preview-bundle-button'
            >
              {I18n.t('turbosquid_products.preview')}
            </button>
          </OverlayTrigger>
          <button
            type="submit"
            name="publish"
            data-testid="publish-bundle"
            className={`btn btn-default btn-action-top btn-publish ${isPublishDisabled ? 'disabled' : ''}`}
            disabled={isPublishDisabled || productLimits()}
            onClick={handlePublishDraft}
          >
            {I18n.t('publish', i18nOptions)}
          </button>
          <BundlesPublishModal
            show={showPublishModal}
            hideModal={() => setShowPublishModal(false)}
            // discardDraft={handleDiscardDraft}
          />
          {isPublishing && (
            <div className="col-sm-1 spinner-container-bundles pull-right">
              <ReactSpinner />
            </div>
          )}
        </div>
      </div>
    )
}
