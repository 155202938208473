/* global I18n, gon */
import React, { useContext } from 'react'
import { BundlesContext } from './BundlesProvider'

const i18nOptions = {
  scope: 'turbosquid.products.bundles',
}

export default function ProductSearchResult({ product, onChange }) {
  
  const {
    searchSelect
  } = useContext(BundlesContext)

  const {
    selectedSearchResults
  } = searchSelect

  const selectedProducts = { ...selectedSearchResults }

  const isIncluded = (productId, selectedProducts) => {
    if (Object.keys(selectedProducts).includes(productId)) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      <div className="image-container">
        <img src={product.thumbnail} alt={product.name} />
      </div>
      <label htmlFor={`product_${product.id}`}>
        <span>
          {product.name}
        </span>
      </label>
      <div className="price">
        {product.price}
      </div>
      { product.license_id === 7 ? (
        <div className='ineligible-button'>
          {I18n.t('ineligible', i18nOptions)}
        </div>
      ) : (
      <div className="add">
        <button onClick={onChange} data-testid="add-bundle" className={isIncluded(product.id, selectedProducts) ? 'included-button' : 'add-button'}>
          {isIncluded(product.id, selectedProducts) ? 'Included' : 'ADD'}
        </button>
      </div>
      )
      }
    </>
  )
}